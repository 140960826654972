import React from "react";
import styled from "styled-components";
import { Flex, Box } from "@rebass/grid";
import CI from "@trackcode/ci";

const Tile = ({ picture, headline, description, rotateZ = 0 }) => (
  <Flex>
    <Wrapper rotateZ={rotateZ}>
      <PictureWrapper>{picture}</PictureWrapper>
      <Headline>{headline}</Headline>
      <Description>{description}</Description>
    </Wrapper>
  </Flex>
);

export default Tile;

const Wrapper = styled(Box)`
  width: 350px;
  height: 330px;
  margin: 28px;
  padding: 8px;
  background: #ffffff;
  box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.2);
  line-height: 1.7em;
  transform: rotateZ(${({ rotateZ }) => rotateZ}deg);
`;

const Headline = styled(Box)`
  font-family: "Permanent Marker";
  font-size: 27px;
  color: ${CI.color.brand};
  text-align: center;
  margin-top: 8px;
`;
const Description = styled(Box)`
  font-size: 17px;
  font-style: italic;
  font-weight: bold;
  text-align: center;
`;

const PictureWrapper = styled.div``;
