import React from "react";
import { StaticQuery, graphql } from "gatsby";
import Img from "gatsby-image";
import { Flex } from "@rebass/grid";
import Layout from "../components/Layout";
import AppBar from "../components/AppBar";
import Tile from "../components/Tile";

const FILENAME_SEPARATOR = "@";

const headlineFromFileName = fileName => {
  const [headline] = fileName.split("@");
  return headline;
};

const descriptionFromFileName = fileName => {
  if (!fileName.includes(FILENAME_SEPARATOR)) {
    return "";
  }
  const [, description] = fileName.split(FILENAME_SEPARATOR);
  return FILENAME_SEPARATOR + description;
};

const IndexPage = () => {
  const random = () =>
    Math.random() * (Math.round(Math.random()) > 0.5 ? -1 : 1);
  return (
    <StaticQuery
      query={graphql`
        query {
          picturesYaml {
            file
            title
          }
          allFile(
            filter: { extension: { regex: "/(jpg)|(png)|(webp)|(jpeg)/" } }
          ) {
            edges {
              node {
                name
                childImageSharp {
                  fluid(maxWidth: 800) {
                    ...GatsbyImageSharpFluid_withWebp
                  }
                }
              }
            }
          }
        }
      `}
      render={({ picturesYaml, allFile }) => (
        <Layout appBar={<AppBar />}>
          <Flex flexWrap="wrap" justifyContent="center" alignItems="flex-start">
            {allFile.edges.map(({ node }, key) => (
              <Tile
                key={key}
                picture={<Img fluid={node.childImageSharp.fluid} />}
                headline={headlineFromFileName(node.name)}
                description={descriptionFromFileName(node.name)}
                rotateZ={random}
              />
            ))}
          </Flex>
        </Layout>
      )}
    />
  );
};

export default IndexPage;
